<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="url(#user-gradient)" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2577 15.4566C16.1743 15.4564 16.0291 15.4669 15.6456 15.4963C14.4436 15.5885 13.0506 15.667 11.7869 15.667C10.5761 15.667 9.23039 15.5949 8.0447 15.5079C7.64712 15.4787 7.49613 15.4683 7.41105 15.469C6.27081 15.4777 5.38563 16.2145 5.17012 17.3342C5.15398 17.4181 5.13908 17.5482 5.10063 17.8988C5.06362 18.2363 5.05088 18.3589 5.04894 18.4528C5.02395 19.6623 5.94007 20.6843 7.14501 20.7913C7.23862 20.7996 7.36184 20.8004 7.70135 20.8004L15.999 20.8003C16.3722 20.8003 16.5082 20.7995 16.6085 20.79C17.8679 20.6699 18.7939 19.5552 18.681 18.2952C18.672 18.1948 18.6479 18.0609 18.5794 17.694C18.5174 17.3612 18.4938 17.2381 18.472 17.1573C18.1904 16.1139 17.3384 15.4596 16.2577 15.4566ZM15.5865 14.0968C15.907 14.0722 16.1142 14.0562 16.2615 14.0566C17.9735 14.0613 19.3775 15.1396 19.8236 16.7924C19.862 16.9346 19.8962 17.1182 19.9483 17.3974L19.9637 17.4801C20.0213 17.789 20.0597 17.9944 20.0754 18.1703C20.257 20.1973 18.7674 21.9904 16.7414 22.1836C16.5656 22.2004 16.3567 22.2004 16.0424 22.2004L7.70135 22.2004C7.68811 22.2004 7.67507 22.2004 7.66223 22.2004C7.37589 22.2004 7.18505 22.2004 7.02117 22.1858C5.08279 22.0137 3.60904 20.3695 3.64924 18.4239C3.65264 18.2594 3.67346 18.0697 3.70471 17.7851C3.70611 17.7723 3.70753 17.7594 3.70897 17.7462C3.71055 17.7319 3.71209 17.7177 3.71362 17.7038C3.74582 17.41 3.76694 17.2172 3.79535 17.0696C4.13695 15.2948 5.59297 14.0829 7.40032 14.069C7.5507 14.0679 7.76508 14.0836 8.09727 14.108C8.11361 14.1092 8.13024 14.1104 8.14716 14.1117C9.31707 14.1975 10.6248 14.267 11.7869 14.267C12.9998 14.267 14.3531 14.1913 15.5386 14.1004L15.5865 14.0968Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.30078 7.50078C7.30078 4.90504 9.40504 2.80078 12.0008 2.80078C14.5965 2.80078 16.7008 4.90504 16.7008 7.50078V8.07221C16.7008 10.3524 14.8524 12.2008 12.5722 12.2008H11.4294C9.1492 12.2008 7.30078 10.3524 7.30078 8.07221V7.50078ZM12.0008 4.20078C10.1782 4.20078 8.70078 5.67824 8.70078 7.50078V8.07221C8.70078 9.57916 9.9224 10.8008 11.4294 10.8008H12.5722C14.0792 10.8008 15.3008 9.57916 15.3008 8.07221V7.50078C15.3008 5.67824 13.8233 4.20078 12.0008 4.20078Z"
    />
    <defs>
      <linearGradient id="user-gradient" x1="20.0904" y1="18.18" x2="3.64844" y2="18.18" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_15087_64843"
        x1="16.7008"
        y1="7.56028"
        x2="7.30078"
        y2="7.56028"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
